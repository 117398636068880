<template>
  <div v-if="checkForActivatedLayers">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <strong class="pt-4">
      {{ $t('ServiceCard.timeIntervallLabel') }}
    </strong>
    <v-row class="ma-0 mt-1">
      <v-col
        :cols="dailyData ? 12 : 6"
        class="pb-0 pt-0 pl-0"
      >
        <v-menu
          ref="rangeMenuStart"
          v-model="rangeMenuStart"
          :close-on-content-click="false"
          :return-value.sync="rangeDate[0]"
          :attach="`.rangeMenuStart${serviceCardId}`"
          transition="scale-transition"
          max-width="300px"
          min-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="rangeDate[0]"
              chips
              small-chips
              :label="$t('ServiceCard.startDate')"
              :class="`rangeMenuStart${serviceCardId}`"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="endDateDisabled = true; endTimeDisabled = true; rangeDate = [null, null]; archiveStartTime = '00:00'; archiveEndTime = '00:00'"
              @click="changeMinMaxDates()"
            />
          </template>
          <v-date-picker
            v-model="rangeDate[0]"
            no-title
            scrollable
            full-width
            :min="minDate"
            :max="maxDate"
            :locale="app_user.app_user_data[0].language"
            first-day-of-week="1"
            @input="saveRangeMenuStart"
          >
            <template v-slot:default="{ input }">
              <v-alert
                dense
                outlined
                type="warning"
              >
                {{ $t('ServiceCardArchive.maxRangeDescription') }}: {{ maxRange }} {{ $t(`ServiceCardArchive.${maxRangeType}`) }}.
              </v-alert>
              {{ input }}
            </template>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        v-if="!dailyData"
        cols="6"
        class="pb-0 pt-0"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="archiveStartTime"
              :label="$t('ServiceCard.startTime')"
              prepend-icon="mdi-clock-time-four-outline"
              value="00:00"
              type="time"
              max-width="290px"
              min-width="290px"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $t('ServiceCard.localTime') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col
        :cols="dailyData ? 12 : 6"
        class="pt-0 pl-0"
      >
        <v-menu
          ref="rangeMenuEnd"
          v-model="rangeMenuEnd"
          :close-on-content-click="false"
          :return-value.sync="rangeDate[1]"
          :attach="`.rangeMenuEnd${serviceCardId}`"
          transition="scale-transition"
          :disabled="endDateDisabled"
          max-width="300px"
          min-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="rangeDate[1]"
              chips
              small-chips
              :label="$t('ServiceCard.endDate')"
              :class="`rangeMenuEnd${serviceCardId}`"
              prepend-icon="mdi-calendar"
              readonly
              :disabled="endDateDisabled"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="endTimeDisabled = true; archiveEndTime = '00:00'"
            />
          </template>
          <v-date-picker
            v-model="rangeDate[1]"
            no-title
            scrollable
            full-width
            :min="rangeDate[0]"
            :max="calculateEndDate()"
            :locale="app_user.app_user_data[0].language"
            first-day-of-week="1"
            @input="saveRangeMenuEnd()"
          >
            <template v-slot:default="{ input }">
              <v-alert
                dense
                outlined
                type="warning"
              >
                {{ $t('ServiceCardArchive.maxRangeDescription') }}: {{ maxRange }} {{ $t(`ServiceCardArchive.${maxRangeType}`) }}.
              </v-alert>
              {{ input }}
            </template>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        v-if="!dailyData"
        cols="6"
        class="pt-0"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="archiveEndTime"
              :label="$t('ServiceCard.endTime')"
              prepend-icon="mdi-clock-time-four-outline"
              clear-icon="mdi-calendar"
              :value="archiveEndTime"
              type="time"
              :disabled="endTimeDisabled"
              max-width="290px"
              min-width="290px"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $t('ServiceCard.localTime') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <v-btn
        id="submit"
        color="#0da344"
        small
        style="color:white"
        class="mb-4"
        :disabled="disableConfirmButton()"
        @click="changeDate()"
      >
        {{ $t('ServiceCard.confirmFilter') }}
      </v-btn>
    </div>
    <v-alert
      :value="tooSmallRange"
      dense
      dismissible
      type="warning"
      icon="mdi-alert-circle-outline"
      class="mt-4"
      transition="scale-transition"
    >
      {{ $t('ServiceCard.warningTooSmallRange') }}{{ animationStep }} {{ $t(`dateunit.${dateType}`) }}
    </v-alert>
    <animation-player
      v-if="renderAnimation"
      :selected-raster-layer="selectedRasterLayer"
      :selected-vector-layer="selectedVectorLayer"
      :service-card-id="serviceCardId"
      :animation-limit-range-array="animationLimitRangeArray"
      :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
      :animation-step-date-type="animationStepDateType"
      :animation-step="animationStep"
      :min-step="archiveMinStep"
      :max-step="archiveMaxStep"
      :is-animation="isAnimation"
      :service-name="serviceName"
      :layer-type="layerType"
      :animation-time-array="animationTimeArray"
      :animation-empty-steps="animationEmptySteps"
      :animation-start-index="animationStartIndex"
      :no-features-found="noFeaturesFound"
      :no-wmts-features-found="noWmtsFeaturesFound"
      @no-raster-features-found="$emit('no-raster-features-found', $event)"
      @no-vector-features-found="$emit('no-vector-features-found', $event)"
    />
    <raster-layer-table-chart-buttons
      v-if="!disableTableChartButtons && (selectedRasterLayer != null && Object.keys(selectedRasterLayer).length > 0)"
      :raster-layers="rasterLayers"
      :selected-raster-layer="selectedRasterLayer"
      :min-step="archiveMinStep"
      :max-step="archiveMaxStep"
      :is-table="isTable"
      :is-graph="isGraph"
      :is-animation="isAnimation"
      :service-name="serviceName"
      :table-chart-range-date="tableChartRangeDate"
      :is-daily-data="dailyData"
    />
    <vector-layer-popup
      v-if="!disableTableChartButtons && (selectedVectorLayer != null && Object.keys(selectedVectorLayer).length > 0)"
      :selected-vector-layer="selectedVectorLayer"
      :vector-layers="vectorLayers"
      :service-card-id="serviceCardId"
      :animation-step-date-type="animationStepDateType"
      :animation-step="animationStep"
      :min-step="archiveMinStep"
      :max-step="archiveMaxStep"
      :service-name="serviceName"
      :is-table="isTable"
      :is-animation="isAnimation"
      :table-chart-range-date="tableChartRangeDate"
      :is-daily-data="dailyData"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { toLonLat } from 'ol/proj';
import { RASTER_BASE_URL } from '@/constants.js';
import { getFormattedCurrentDate, getFormattedDate, dateUnit } from '@/utils.js';

export default {
  components: {
    AnimationPlayer: () => import('@/components/utils/AnimationPlayer'),
    RasterLayerTableChartButtons: () => import('@/components/utils/RasterLayerTableChartButtons'),
    VectorLayerPopup: () => import('@/components/utils/VectorLayerPopup')
  },
  props: {
    serviceCardId: {
      type: String,
      default: ''
    },
    animationStepDateType: {
      type: String,
      default: ''
    },
    animationStep: {
      type: String,
      default: ''
    },
    layerType: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    isTable: {
      type: Boolean,
      default: false
    },
    isGraph: {
      type: Boolean,
      default: false
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    selectedRasterLayer: {
      type: Object,
      default: null
    },
    selectedVectorLayer: {
      type: Object,
      default: null
    },
    animationLimitRangeArray: {
      type: Array,
      default: () => []
    },
    animationLimitRangeArrayPolicy: {
      type: Array,
      default: () => []
    },
    rasterLayers: {
      type: Array,
      default: () => []
    },
    vectorLayers: {
      type: Array,
      default: () => []
    },
    animationTimeArray: {
      type: Array,
      default: () => []
    },
    animationEmptySteps: {
      type: Array,
      default: () => []
    },
    animationStartIndex: {
      type: Number,
      default: 0
    },
    noFeaturesFound: {
      type: Boolean,
      default: false
    },
    noWmtsFeaturesFound: {
      type: Boolean,
      default: false
    },
    tableChartRangeDate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      archiveStartTime: '00:00',
      archiveEndTime: '00:00',
      minDate: '',
      maxDate: '',
      endMaxTime: '00:00',
      endDateDisabled: true,
      endTimeDisabled: true,
      rangeMenuStart: false,
      rangeMenuEnd: false,
      tooSmallRange: false,
      overlay: false,
      disableTableChartButtons: true,
      openVectorLayerTableChartPopup: false,
      renderAnimation: false,
      archiveMinStep: new Date(),
      archiveMaxStep: new Date(),
      rangeDate: [null, null]
    };
  },
  computed: {
    ...mapState(['map', 'app_user', 'geoserver_data', 'table_chart']),
    checkForActivatedLayers() {
      if (this.layerType === 'RASTER') {
        return Object.keys(this.selectedRasterLayer).length !== 0;
      } else {
        return Object.keys(this.selectedVectorLayer).length !== 0;
      }
    },
    dateType() {
      return dateUnit(this.animationStepDateType);
    },
    dailyData() {
      return this.animationStepDateType === 'dynamic_months' || this.animationStepDateType === 'dynamic_days' || (this.animationStepDateType === 'dynamic_hours' && this.animationStep >= 24);
    },
    maxRangeType() {
      switch (this.animationStepDateType.split('_')[1]) {
        case 'months':
          return 'yearsRange';
        case 'days':
          return 'monthsRange';
        default:
          return 'monthRange';
      }
    },
    maxRange() {
      switch (this.maxRangeType) {
        case 'yearsRange':
          return 10;
        case 'yearRange':
          return 1;
        case 'monthsRange':
          return 3;
        default:
          return 1;
      }
    }
  },
  watch: {
    $route() {},
    animationTimeArray: {
      async handler(newValue) {
        if (newValue && this.animationTimeArray.length > 0) {
          this.openSelectedLayer();
        }

        this.overlay = false;
      }
    }
  },
  methods: {
    ...mapActions(['loadVectorLayer', 'removeLayerByServiceID', 'loadRasterLayer', 'removeEveryRasterLayer', 'checkOpacityServiceID', 'removePreviousVectorLayer', 'resetAnimationTime', 'getLayerExtent']),
    saveRangeMenuStart() {
      this.$refs.rangeMenuStart.save(this.rangeDate[0]);
      this.endDateDisabled = false;
      this.setEndMaxTime();

      if (!this.rangeDate[1]) {
        this.rangeDate[1] = this.rangeDate[0];
        this.endTimeDisabled = false;
      } else if (new Date(this.rangeDate[1]) < new Date(this.rangeDate[0])) {
        this.rangeDate[1] = this.rangeDate[0];
      } else if (new Date(this.rangeDate[1]) > new Date(this.calculateEndDate())) {
        this.rangeDate[1] = this.calculateEndDate();
      }

      this.saveRangeMenuEnd();
    },
    saveRangeMenuEnd() {
      this.$refs.rangeMenuEnd.save(this.rangeDate[1]);
      this.endTimeDisabled = false;
    },
    async changeDate() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const dataFine = new Date(this.rangeDate[1]);
      dataFine.setHours(0, 0, 0, 0);

      if (dataFine >= today) {
        if (this.archiveEndTime > this.endMaxTime) {
          this.archiveEndTime = this.endMaxTime;
        }
      }

      this.overlay = true;
      this.tooSmallRange = false;
      this.disableTableChartButtons = false;

      await this.removeLayerByServiceID(this.serviceCardId);

      if (this.rangeDate[0] === this.rangeDate[1]) {
        this.archiveEndTime = (this.archiveStartTime < this.archiveEndTime) ? this.archiveEndTime : this.archiveStartTime;
      }

      this.archiveMinStep = new Date(this.rangeDate[0]);
      this.archiveMaxStep = new Date(this.rangeDate[1]);

      const [startHours, startMinutes] = !this.dailyData ? this.archiveStartTime.split(':') : ['23', '59'];
      this.archiveMinStep.setHours(parseInt(startHours), parseInt(startMinutes));

      const [endHours, endMinutes] = !this.dailyData ? this.archiveEndTime.split(':') : ['23', '59'];
      this.archiveMaxStep.setHours(parseInt(endHours), parseInt(endMinutes));

      if (this.dailyData) {
        if (this.layerType === 'RASTER') {
          this.archiveMinStep.setDate(this.archiveMinStep.getDate() - 1);
        }

        const lastStep = new Date(this.archiveMinStep);

        if (this.layerType === 'VECTOR') {
          if (new Date(this.archiveMinStep).getTime() !== new Date(this.archiveMaxStep).getTime()) {
            lastStep.setDate(lastStep.getDate() - 1);
          }

          this.archiveMinStep.setDate(this.archiveMinStep.getDate() - 1);
        }

        while (lastStep <= this.archiveMaxStep) {
          if (this.animationStepDateType === 'dynamic_days') {
            lastStep.setDate(lastStep.getDate() + parseInt(this.animationStep));
          } else {
            lastStep.setDate(lastStep.getDate() + parseInt(this.animationStep / 24));
            lastStep.setHours(lastStep.getHours() + (this.animationStep % 24));
          }
        }

        if (this.animationStepDateType === 'dynamic_days') {
          lastStep.setDate(lastStep.getDate() - parseInt(this.animationStep));
        } else {
          lastStep.setDate(lastStep.getDate() - parseInt(this.animationStep / 24));
          lastStep.setHours(lastStep.getHours() - (this.animationStep % 24));
        }

        this.archiveMaxStep = lastStep;
      }

      // const timeStepMs = timeToMilliseconds(this.animationStep, this.animationStepDateType);
      // const userRangeMs = this.archiveMaxStep - this.archiveMinStep;
      // if (userRangeMs - timeStepMs >= 0) {
      this.$emit('set-animation-steps', [this.archiveMinStep, this.archiveMaxStep]);
      // } else {
      //   this.overlay = false;
      //   this.tooSmallRange = true;
      //   this.disableTableChartButtons = true;
      // }
      this.updateServices();
    },
    async openSelectedLayer() {
      if (this.layerType === 'RASTER') {
        this.map.sliderOpacityValue = 60;
        this.checkOpacityServiceID(this.serviceCardId);
        this.removeEveryRasterLayer();
        await this.openRasterLayer(this.selectedRasterLayer);
        this.$emit('closeRasterService', this.serviceCardId);
      } else {
        await this.openVectorLayer();
      }

      this.updateServices();
      this.resetAnimationTime();

      const centerCoordinates = toLonLat(this.map.OLmap.getView().getCenter());
      const timeStamp = Date.now();
      this.$gtag.event('Intervallo temporale', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - ${this.serviceName}`,
        event_label: `${timeStamp} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.rangeDate[0]} ${this.archiveStartTime} - ${this.rangeDate[1]} ${this.archiveEndTime}`
      });
      this.$gtag.event('Centroide mappa', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - Lat ${centerCoordinates[1].toFixed(4)} - Lon ${centerCoordinates[0].toFixed(4)}`,
        event_label: `${timeStamp} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.serviceName}: ${this.layerType === 'RASTER' ? this.selectedRasterLayer.layer_name : this.selectedVectorLayer.layer_name} - ${this.rangeDate[0]} ${this.archiveStartTime} - ${this.rangeDate[1]} ${this.archiveEndTime}`
      });
      this.overlay = false;
      this.$emit('google-tag');
    },
    async openRasterLayer(raster) {
      this.geoserver_data.rasterLegend_selectedLabel = [];

      const extent = await this.getLayerExtent([raster.ws_name, raster.layer_name, raster.layer_type, raster.service_id]);
      const rasterParams = {
        url: `${RASTER_BASE_URL}/fe_ogc/geoserver/wms`,
        params: {
          ENV: '',
          GROUP: this.$route.params.id,
          LAYERS: `${raster.ws_name}:${raster.layer_name}`,
          TIME: this.animationTimeArray[this.animationStartIndex],
          STYLES: raster.layer_style,
          USERNAME: this.app_user.username,
          SERVICE_NAME: this.serviceName,
          FORMAT: 'image/vnd.jpeg-png8'
        },
        TYPE: 'RASTER',
        LAYER_SERVICE_ID: this.serviceCardId,
        EXTENT: extent
      };

      this.map.rasterMarkerDate = rasterParams.params.TIME;

      await this.loadRasterLayer(rasterParams);
    },
    async openVectorLayer() {
      const extent = await this.getLayerExtent([this.selectedVectorLayer.ws_name, this.selectedVectorLayer.layer_name, this.selectedVectorLayer.layer_type, this.selectedVectorLayer.service_id]);
      const vectorParams = {
        params: {
          GROUP: this.$route.params.id,
          LAYERS: `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`,
          TIME: this.animationTimeArray[this.animationStartIndex],
          STYLES: this.selectedVectorLayer.layer_style,
          USERNAME: this.app_user.username,
          SERVICE_NAME: this.serviceName,
          PARAM_RANGE: this.map.vectorParamRange[this.serviceCardId]
        },
        TYPE: 'VECTOR',
        LAYER_SERVICE_ID: this.serviceCardId,
        EXTENT: extent
      };
      this.map.sliderOpacityValue = 60;
      this.checkOpacityServiceID(this.serviceCardId);

      this.vectorLayers.forEach(layer => {
        if (layer.layer_name.includes('ignore')) {
          const index = this.table_chart.completeVectorChartItems.findIndex(item => item.service_id === layer.service_id);
          const obj = { layer_name: layer.layer_name,
            ws_name: layer.ws_name,
            service_id: layer.service_id,
            service_name: this.serviceName,
            minStep: this.archiveMinStep,
            maxStep: this.archiveMaxStep,
            isGraph: layer.is_graph,
            animationStep: this.animationStep,
            animationStepDateType: this.animationStepDateType
          };

          this.table_chart.completeVectorChartItems.some(item => item.service_id === layer.service_id) ?
            this.table_chart.completeVectorChartItems[index] = obj
            :
            this.table_chart.completeVectorChartItems.push(obj);
        }
      });

      const vectorToRemove = {
        service_id: this.serviceCardId,
        layer_name: `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`
      };
      await this.removePreviousVectorLayer(vectorToRemove);

      await this.loadVectorLayer(vectorParams);
    },
    changeMinMaxDates() {
      const today = new Date();

      if (this.dailyData) {
        today.setDate(today.getDate() - 1);
      }

      this.minDate = getFormattedCurrentDate(this.minStep);
      this.maxDate = getFormattedCurrentDate(today);
    },
    disableConfirmButton() {
      if (this.dailyData) {
        return !(((this.rangeDate[1] !== null && this.rangeDate[0] !== null)));
      } else {
        return !(((this.rangeDate[1] !== null && this.rangeDate[0] !== null && this.archiveStartTime !== '' && this.archiveEndTime !== '')));
      }
    },
    dateToString(data) {
      const anno = data.getFullYear();
      const mese = ('0' + (data.getMonth() + 1)).slice(-2);
      const giorno = ('0' + data.getDate()).slice(-2);

      return `${anno}-${mese}-${giorno}`;
    },
    calculateEndDate() {
      const maxRangeDate = new Date(this.rangeDate[0]);
      const endDate = new Date(this.maxDate);

      if (this.maxRangeType === 'yearsRange' || this.maxRangeType === 'yearRange') {
        maxRangeDate.setFullYear(maxRangeDate.getFullYear() + this.maxRange);
      } else {
        maxRangeDate.setMonth(maxRangeDate.getMonth() + this.maxRange);
      }

      const date = (maxRangeDate < endDate) ? maxRangeDate : endDate;

      return this.dateToString(date);
    },
    setEndMaxTime() {
      if (!this.dailyData) {
        const currentDateTime = new Date();
        const stepType = this.animationStepDateType.split('_')[1];

        if (stepType === 'hours') {
          currentDateTime.setHours(currentDateTime.getHours() - this.animationStep);
          currentDateTime.setMinutes('00');
        } else if (stepType === 'minutes') {
          currentDateTime.setMinutes(currentDateTime.getMinutes() - 20);
          const roundedMinutes = Math.floor(currentDateTime.getMinutes() / this.animationStep) * this.animationStep;
          currentDateTime.setMinutes(roundedMinutes);
        }

        const hours = String(currentDateTime.getHours()).padStart(2, '0');
        const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
        this.endMaxTime = `${hours}:${minutes}`;
      }
    },
    reloadLegend() {
      this.map.reloadLegend = true;
      this.$nextTick(() => {
        this.map.reloadLegend = false;
      });
    },
    updateServices() {
      if (!this.noFeaturesFound && !this.noWmtsFeaturesFound) {
        this.renderAnimation = false;
        this.$nextTick(() => {
          this.renderAnimation = true;
        });
        this.reloadLegend();
      }
    }
  }
};
</script>

<style scoped>
.v-text-field >>> label {
  font-size: 0.8em;
}
</style>
